import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'slick-carousel/slick/slick-theme.css';
import "./sass/style.scss";
import 'viewport-extra';

AOS.init({
  offset: 50,
  duration: 1000,
  easing: 'linear',
  once: true,
});

$(function(){

  $('.nav-toggle').on('click', function () {
    $('.gNav_toggle').toggleClass('active');
    $('.gNav_overlay').toggleClass('open');
    $('.siteHead').toggleClass('visible');
    if ($("body").css('overflow') == 'hidden') {
      $("body").css({ overflow: 'visible' });
    } else if ($("body").css('overflow') == 'visible') {
      $("body").css({ overflow: 'hidden' });
    };
  });


  $.fn.startBar = function(index) {
    this
      .eq(index)
      .animate({ width: '100%' }, { duration: 5000, easing: 'linear', } );
  }
  $('.firstView_slider-pc').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  })
  $('.firstView_slider-pc').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: false,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true
  })
    .on({
      beforeChange: function(event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
        $(".slick-slide", this).eq(nextSlide).addClass("zooming");
      },
      afterChange: function(event, slick, currentSlide) {
        $(".preve-slide", this).removeClass("preve-slide zooming");
        if (currentSlide === 0)
          $('.firstView_bar-pc').css({width: 0});
        $('.firstView_bar-pc').startBar(currentSlide);
      }
    });
  $('.firstView_bar-pc').startBar(0);

  $('.firstView_slider-pc').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  });

  $('.firstView_slider-sp').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  });
  $('.firstView_slider-sp').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  })
  $('.firstView_slider-sp').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: false,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true
  })
    .on({
      beforeChange: function(event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
        $(".slick-slide", this).eq(nextSlide).addClass("zooming");
      },
      afterChange: function(event, slick, currentSlide) {
        $(".preve-slide", this).removeClass("preve-slide zooming");
        if (currentSlide === 0)
          $('.firstView_bar-sp').css({width: 0});
        $('.firstView_bar-sp').startBar(currentSlide);
      }
    });
  $('.firstView_bar-sp').startBar(0);


  $('.firstView_slider-sp').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  });



  $('.hinichijo_slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: false,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true
  })
    .on({
      afterChange: function(event, slick, currentSlide) {
        if (currentSlide === 0)
          $('.hinichijo_bar').css({width: 0});
        $('.hinichijo_bar').startBar(currentSlide);
      }
    });

  $('.hinichijo_bar').startBar(0);

  $('.indexWorks_list').not('.slick-initialized').slick({
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 300,
    fade: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

});




/* ----------------------------------
ローディング
---------------------------------- */

const loading = document.getElementById('loading');
/* 読み込み完了 */
window.addEventListener('load', stopload);

/* 6秒経ったら強制的にロード画面を非表示にする */
setTimeout(stopload,10000, '');

/* ロード画面を非表示にする処理 */
function stopload(){
  loading.classList.add('is-loaded');
}

/* ----------------------------------
スムーススクロール
---------------------------------- */
document.addEventListener("click", e => {
  const target = e.target;
  // clickした要素がclass属性、smooth-scrollを含まない場合は処理を中断
  if (!target.classList.contains("smoothscroll")) return;
  e.preventDefault();
  const targetId = target.hash;
  const targetElement = document.querySelector(targetId);
  // 画面上部から要素までの距離
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // スクロール位置に持たせるバッファ
  const buffer = 100;
  const top = rectTop + offsetTop - buffer;
  console.log(top);

  window.scrollTo({
    top,
    behavior: "smooth"
  });
});

/* ----------------------------------
ヘッダー動作
---------------------------------- */
const header = document.querySelector('.siteHead');
if(document.querySelector('.firstView') ) {
  const headHiddenOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const fvObserver = new IntersectionObserver(doHidden, headHiddenOptions);
  fvObserver.observe(document.querySelector('.firstView'));
  function doHidden(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('is-front');
      }else{
        header.classList.remove('is-front');
      }
    });
  }
}

/* ----------------------------------
スクロール連動拡大
---------------------------------- */
// 今回の交差を監視する要素
const scaleUpBoxes = document.querySelectorAll(".un_new2022ScaleUp");

const options = {
  root: null, // 今回はビューポートをルート要素とする
  rootMargin: "0px", // ビューポートの中心を判定基準にする
  threshold: buildThresholdList()
};
const observer = new IntersectionObserver(scaleUp, options);
// それぞれのboxを監視する
scaleUpBoxes.forEach(box => {
  observer.observe(box);

  // ページ読み込み時に要素より下にいる場合、要素を拡大
  let boxRect = box.getBoundingClientRect();
  let boxY = boxRect.top;
  if (boxY < 0) {
    box.style.transform = "scale(1)";
  };
});

// threshold の設定
function buildThresholdList() {
  let thresholds = [];
  let numSteps = 50;

  for (let i = 1; i <= numSteps; i++) {
    let ratio = i / numSteps;
    thresholds.push(ratio);
  }
  return thresholds;
}

// 要素が表示されたら実行する動作
function scaleUp(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      let ratio = entry.intersectionRatio * 0.3;
      let rectTop = entry.intersectionRect.top;
      let scale = 0.7;

      if (rectTop > 0) {
        entry.target.style.transform = `scale(${scale + ratio})`;
      } else {
        entry.target.style.transform = `scale(1)`;
      }
    }
  });
}